import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Text } from 'theme-ui'

export default () => (
  <>
    <Seo title='Cookies' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Cookie Policy"
          subheader=''
        />
        <Divider />        
        <Box>
          <Text variant='p'>
            Our cookie policy.
          </Text>
        </Box>
      </Main>      
    </Stack>
  </>
)
